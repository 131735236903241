import React, { useState, useEffect } from "react";
import { Paper, Button } from "@material-ui/core";
import Map from "../components/Map";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import * as geolib from "geolib";

const useStyles = makeStyles((theme) => ({
  ...styles,
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const AvailabilityProfileMap = (props) => {
  const [mylocation, setMylocation] = useState(null);
  const [locations, setLocations] = useState([]);

  const [settings, setSettings] = useState({});
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const usersdata = useSelector((state) => state.usersdata);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const settingsdata = useSelector((state) => state.settingsdata);
  const [filter, setFilter] = useState([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (mylocation == null) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setMylocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (error) => {
           //console.log(error)
        }
      );
    }
  }, [mylocation, props.boardType]);

  useEffect(() => {
    setReset(true);
  }, [props.boardType]);

  useEffect(() => {
    if (settingsdata.settings) {
      setSettings(settingsdata.settings);
    }
  }, [settingsdata.settings, props.boardType]);

  const extractCityStateFromAddress = (address) => {
    if(!address) return ""
    const parts = address.split(", ");
    if (parts?.length !== 4) {
      return address;
    }
    const cityState = parts[parts.length - 2].split(" ");
    if (cityState?.length !== 2) {
      return address;
    }
    const city = parts[1];
    const state = cityState[0];

    return `${city}, ${state}`;
  };

  function generateRandomId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  function checkTrailerType(trailerType, driver) {
    return (
      trailerType === driver.trailerType ||
      trailerType === "Select Trailer Type"
    );
  }
  useEffect(() => {
    if (props.data.length > 0) {
      const data =
        (props.searchedRadiusValue && props.searchedlocationValue) || props.selectedDate
          ? filter
          : props.data;
      let mapRoutes = [];
      for (let i = 0; i < data.length; i++) {
        let basicData = {
          location: data[i]?.location || null,
          baseCity: data[i]?.baseCity || null,
          trailerType: data[i].trailerType,
          canBeBooked: data[i]?.canBeBooked,
          route: data[i]?.route,
          routeType: data[i].routeType,
          driver: data[i]?.driver,
          id: data[i]?.id,
          travelRadius: data[i]?.travelRadius,
          frequentRouteDates: data[i]?.frequentRouteDates || null,
          fleetadmin: data[i].fleetadmin,
          ratePerMile:data[i].ratePerMile,
          availableWeight:data[i].availableWeight,
          
        };
        if (data[i].routeType === "Available Capacity") {
          // line segment object pushed to locs

          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;
          const randomId = generateRandomId(8);
          basicData["origin"] = {
            lat: data[i]?.route.lat,
            lng: data[i]?.route.lng,
            add: data[i]?.route.add,
          };
          basicData["destination"] = null;

          mapRoutes.push({
            id: randomId,
            lat: data[i].location.lat,
            lng: data[i].location.lng,
            drivername: data[i].name,
            travelRadius: data[i].travelRadius,
            trailerType: data[i].trailerType,
            currentLocation: locationCityState,
            carImage: "https://cdn-icons-png.flaticon.com/512/6492/6492616.png",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData: basicData,
          });
        }

        if (
          data[i].routeType === "Future Capacity" &&
          data[i]?.route?.emptyRouteFrom
        ) {
          // line segment object pushed to locs

          const randomId = generateRandomId(8);
          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;
          basicData["origin"] = data[i].route.emptyRouteFrom;
          basicData["destination"] = data[i].route.emptyRouteTo;

          mapRoutes.push({
            id: randomId,
            lineSegment: [
              {
                lat: data[i].route.emptyRouteFrom.lat,
                lng: data[i].route.emptyRouteFrom.lng,
              },
              {
                lat: data[i].route.emptyRouteTo.lat,
                lng: data[i].route.emptyRouteTo.lng,
              },
            ],
            drivername: data[i].name,
            trailerType: data[i].trailerType,
            routeType: data[i].route.routeType?.name,
            cityOne: data[i].route.emptyRouteFrom.add,
            cityTwo: data[i].route.emptyRouteTo.add,
            routeDate: data[i].route.routedate
              ? moment(data[i].route.routedate).format("LL")
              : null,
            currentLocation: locationCityState,
            color: "#748f92",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData: basicData,
          });
        }

        if (data[i].routeType === "Preferred Lane") {
          // line segment object pushed to locs
          const randomId = generateRandomId(8);
          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;

          basicData["origin"] = data[i].route.cityOne;
          basicData["destination"] = data[i].route.cityTwo;

          mapRoutes.push({
            id: randomId,
            lineSegment: [
              {
                lat: data[i].route.cityOne.lat,
                lng: data[i].route.cityOne.lng,
              },
              {
                lat: data[i].route.cityTwo.lat,
                lng: data[i].route.cityTwo.lng,
              },
            ],
            drivername: data[i].name,
            trailerType: data[i].trailerType,
            cityOne: data[i].route.cityOne.add,
            cityTwo: data[i].route.cityTwo.add,
            routeDate: data[i].frequentRouteDates?.join(", "),
            currentLocation: locationCityState,
            color: "#01aac1",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData: basicData,
          });
        }

        if (
          data[i].routeType === "Future Capacity" &&
          data[i]?.location?.lat &&
          data[i]?.baseCity?.lat
        ) {
          // line segment object pushed to locs

          const randomId = generateRandomId(8);

          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;

          basicData["origin"] = data[i]?.location;
          basicData["destination"] = data[i]?.baseCity;

          mapRoutes.push({
            id: randomId,
            lineSegment: [
              { lat: data[i].location.lat, lng: data[i].location.lng },
              { lat: data[i].baseCity.lat, lng: data[i].baseCity.lng },
            ],
            drivername: data[i].name,
            trailerType: data[i].trailerType,
            cityOne: locationCityState,
            cityTwo: data[i].baseCity.add,
            routeDate: data[i].returnHomeDate
              ? moment(data[i].returnHomeDate).format("LL")
              : null,
            currentLocation: locationCityState,
            color: "#748f92",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData: basicData,
          });
        }
      }
      setLocations(mapRoutes);
    }
  }, [
    props.searchedlocationValue,
    props.searchedRadiusValue,
    props.trailerType,
    props.selectedDate,
    props.boardType,
    filter,
  ]);

  function calculateTotalDistance(route) {
    if(!route[0].latitude||!route[1].latitude||!route[0].longitude||!route[1].longitude) return 0
    
    const distanceInMeters = geolib.getDistance(route[0], route[1]);

    // Convert meters to miles
    const distanceInMiles = distanceInMeters * 0.000621371; // 1 meter = 0.000621371 miles

    return distanceInMiles;
  }
  
  function filterRoutesByLocation(routeData, targetLocation, radius) {
    const {latitude, longitude } = targetLocation
    return routeData.filter(route => {

        const distances = route.address.map(address => {
        const earthRadius = 3959; // Earth's radius in mile
        const dLat = toRadians(address.latitude - latitude);
        const dLon = toRadians(address.longitude - longitude);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(latitude)) *
            Math.cos(toRadians(address.latitude)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadius * c;
      });
  
      return distances.some(distance => distance <= radius);
    });
  }
  
  
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  
  useEffect(() => {
    
    let tracking_data = [];
    if (props.data.length > 0) {
      for (const data of props.data) {
        if (data.route.cityOne && data.route.cityTwo) {
          let trackArray = {
            id: data.id,
            address: [
              {
                latitude: data.route.cityOne.lat,
                longitude: data.route.cityOne.lng,
              },
              {
                latitude: data.route.cityTwo.lat,
                longitude: data.route.cityTwo.lng,
              },
            ],
          };
          tracking_data.push(trackArray);
        } else if (data.route?.emptyRouteTo && data.route?.emptyRouteFrom) {
          let trackArray = {
            id: data.id,
            address: [
              {
                latitude: data.route.emptyRouteFrom.lat,
                longitude: data.route.emptyRouteFrom.lng,
              },
              {
                latitude: data.route.emptyRouteTo.lat,
                longitude: data.route.emptyRouteTo.lng,
              },
            ],
          };
          tracking_data.push(trackArray);
        } else if (
          data.routeType === "Future Capacity" &&
          !data.route?.emptyRouteTo
        ) {
          const hostLocation = {
            latitude: data.location.lat,
            longitude: data.location.lng,
          };
          const dropLocation = {
            latitude: data.baseCity.lat,
            longitude: data.baseCity.lng,
          };
          let trackArray = {
            id: data.id,
            address: [hostLocation, dropLocation],
          };
          tracking_data.push(trackArray);
        }

        // else if (data.routeType === "Available Capacityy"  ) {
        //   const hostLocation = {
        //     latitude: data.location.lat,
        //     longitude: data.location.lng,
        //   };
        //   const dropLocation = {
        //     latitude: data.route.lat,
        //     longitude: data.route.lng,
        //   };
        //   let trackArray = {
        //     id: data.id,
        //     address: [hostLocation, dropLocation],
        //   };
        //   tracking_data.push(trackArray);
        // }


      }
    }


    if (
      props.data.length > 0 &&
      props.searchedlocationValue &&
      props.searchedRadiusValue &&
      !props.selectedDate
    ) {
      setReset(false);
      const searchedlocationValue = {
        latitude: props.searchedlocationValue.coords.lat,
        longitude: props.searchedlocationValue.coords.lng,
      };
      const trailerType = props.trailerType;

      const viableTruckRoutes = filterRoutesByLocation(
        tracking_data,
        searchedlocationValue,
        props.searchedRadiusValue,
      );
      
      const alwaysAvailebleRoutes = [];
      for (const data of props.data) {
        if (
          data.routeType === "Available Capacity" &&
          props.searchedlocationValue &&
          props.searchedRadiusValue
        ) {
          let travelRadius = Number(data.travelRadius) * 1609.34;
          const hostLocation = {
            latitude: data.route.lat,
            longitude: data.route.lng,
          };
         
          const pickLocation = {
            latitude: props.searchedlocationValue.coords.lat,
            longitude: props.searchedlocationValue.coords.lng,
          };

          const DistancePickFromLocation = calculateTotalDistance([
            hostLocation,
            pickLocation,
          ]);

          if (DistancePickFromLocation <= props.searchedRadiusValue )
                 alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
             }
    
      }

      let filteredDatas = [];
      for (const data of viableTruckRoutes) {
        let dataExist = props.data.filter((e) => {
          return e.id === data.id;
        });
        if (dataExist.length > 0) {
          if (
            trailerType === dataExist[0].trailerType ||
            trailerType === "Select Trailer Type"
          ) {
            filteredDatas.push({
              ...dataExist[0],
              canBeBooked: true,
            });
          }
        }
      }

      if (alwaysAvailebleRoutes.length > 0)
        setFilter([...alwaysAvailebleRoutes, ...filteredDatas]);
      else setFilter(filteredDatas);
    }

    if (props.selectedDate && !(props.searchedRadiusValue && props.searchedlocationValue)) {
      setReset(false);
      setFilter(Object.values(checkDateAndTrailerType(props.data)));
    }

    if (
      props.selectedDate &&
      props.data.length > 0 &&
      props.searchedRadiusValue &&
      props.searchedlocationValue
    ) {
      setReset(false);
      const searchedlocationValue = {
        latitude: props.searchedlocationValue.coords.lat,
        longitude: props.searchedlocationValue.coords.lng,
      };
      const viableTruckRoutes = filterRoutesByLocation(
        tracking_data,
        searchedlocationValue,
        props.searchedRadiusValue,
      );

      let filteredDatas = [];
      for (const data of viableTruckRoutes) {
        let dataExist = props.data.filter((e) => {
          return e.id === data.id;
        });
        if (dataExist.length > 0)
          filteredDatas.push({
            ...dataExist[0],
            canBeBooked: true,
          });
      }

       const alwaysAvailebleRoutes = [];
      for (const data of props.data) {
        if (
          data.routeType === "Available Capacity" &&
          props.searchedlocationValue &&
          props.searchedRadiusValue
        ) {
          let travelRadius = Number(data.travelRadius) * 1609.34;
          const hostLocation = {
            latitude: data.route.lat,
            longitude: data.route.lng,
          };
         
          const pickLocation = {
            latitude: props.searchedlocationValue.coords.lat,
            longitude: props.searchedlocationValue.coords.lng,
          };

          const DistancePickFromLocation = calculateTotalDistance([
            hostLocation,
            pickLocation,
          ]);

          if (DistancePickFromLocation <= props.searchedRadiusValue )
                 alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
             }

        //   if (travelRadius >= DistancePickFromLocation || DistancePickFromLocation <=props.searchedRadiusValue )
        //     alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
        // } else if (
        //   data.routeType === "Available Capacity" &&
        //   !data.travelRadius
        // ) {
        //   alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
        // }
      }

      let dataReadyToBePushed = [];

      if(alwaysAvailebleRoutes.length>0&&Object.values( checkDateAndTrailerType(alwaysAvailebleRoutes)).length>0){
        for (const data of Object.values( checkDateAndTrailerType(alwaysAvailebleRoutes)) ){
          dataReadyToBePushed.push(data)
        }
       }

      if (
        filteredDatas.length > 0 &&
        Object.values(checkDateAndTrailerType(filteredDatas)).length > 0
      ) {
        for (const data of Object.values(
          checkDateAndTrailerType(filteredDatas)
        )) {
          dataReadyToBePushed.push(data);
        }
      }

      setFilter(dataReadyToBePushed);
    }

    if (
      props.data.length > 0 &&
      props.trailerType &&
      !props.selectedDate &&
      !(props.searchedlocationValue && props.searchedRadiusValue)
    ) {
      const trailerType = props.trailerType;
      const targetedDatas = {};
      setReset(false);
      for (const data of props.data) {
        if (
          checkTrailerType(trailerType, data) &&
          !targetedDatas.hasOwnProperty(data.id)
        ) {
          targetedDatas[data.id] = data;
        }
      }
      setFilter(Object.values(targetedDatas));
    }
  }, [
    props.searchedlocationValue,
    props.searchedRadiusValue,
    props.selectedDate,
    props.boardType,
    props.trailerType,
  ]);
  const checkDateAndTrailerType=(data)=>{
    let filteredData = {};
    const trailerType = props.trailerType;
    data.forEach((driver) => {
      const selectedDate = props.selectedDate? props.selectedDate: null;
      if (selectedDate) {
        // if (!driver?.route?.lat) {
          const foundDate =
            driver?.frequentRouteDates &&
            driver?.frequentRouteDates?.length > 0 &&
            driver?.frequentRouteDates?.includes(
              moment(selectedDate).format("dddd")
            );
          
          if (foundDate) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }
  
          if (
            driver?.route?.routedate &&
            moment(new Date(selectedDate)).format("L") ===
            moment(new Date(driver?.route?.routedate)).format("L")
          ) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }
  
          if (
            (driver?.routeType === "Future Capacity" && !driver?.route?.emptyRouteFrom)&&
            moment(new Date(selectedDate)).format("L") ===
              moment(new Date(driver?.route)).format("L")
          ) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }

          if(driver?.routeType === "Available Capacity" && moment(new Date(selectedDate)).isAfter(moment(new Date()).add(3, 'hours')) &&  moment(new Date(selectedDate)).format("L") === moment(new Date()).format("L")){
            if (checkTrailerType(trailerType, driver) && !filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }


        // } else {
        //   if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
        //     filteredData[driver.id] = driver;
        //   }
        // }
      }
    });

    return filteredData

  }


  const clearFilterAndMatch = () => {
    setReset(true);
    setFilter([]);
    props.setSearchedlocationValue(null);
    props.setSearchedRadiusValue(null);
    props.setSelectedDate(null);
    props.setShowAllLocations(false);
    props.setTrailerType("Select Trailer Type");
  };

  const [centerOfMap,setCenterOfMap] = useState({ lat: 39.8282, lng: -98.5795 })

  const getUserLocation = () => {
    if (navigator.geolocation) {
  
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenterOfMap({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
        }
      );
    } else {
    }
  };
  
    
  useEffect(()=>{
    if(props.searchedlocationValue?.coords){
      setCenterOfMap(props.searchedlocationValue?.coords)
    }else{
      getUserLocation();
    }
  },[props.searchedlocationValue,props.searchedRadiusValue])

  const classes = useStyles();

    // Function to evaluate viability of truck routes based on user input
  function checkIfPickUpAndDropIsAlongTheRoutes(userPickUp, userDropOff, truckRoutes) {
      function calculateBearing(point1, point2) {
        return geolib.getRhumbLineBearing(point1, point2);
      }
  
      function isDirectionAligned(userRoute, truckRoute) {
        const userAvgBearing = calculateAverageBearing(userRoute);
        const truckAvgBearing = calculateAverageBearing(truckRoute);
  
        const firstCondition= Math.abs(userAvgBearing - truckAvgBearing) < 15
        const secondCondition = Math.abs(userAvgBearing - truckAvgBearing) > 165
        const thirdCondition = Math.abs(userAvgBearing - truckAvgBearing) < 195
        
       
        return firstCondition || (secondCondition && thirdCondition);
      }
  
      function calculateAverageBearing(route) {
        let totalBearing = 0;
        for (let i = 0; i < route.length - 1; i++) {
          totalBearing += calculateBearing(route[i], route[i + 1]);
        }
        return totalBearing / (route.length - 1);
      }
  
      function isRouteSegmentConsistent(userBearing, truckSegment) {
        const truckSegmentBearing = calculateBearing(
          truckSegment[0],
          truckSegment[1]
        );
        return 90 < Math.abs(userBearing - truckSegmentBearing) <= 30; // Allow some tolerance
      }
  
     
      function calculateProximityScore(userRoute, truckRoute) {
        let minDistance = Infinity;
        const weightFactors = [1, 0.8, 0.6]; // Higher weight closer to start/end

        // Check proximity in both directions (forward and backward)
        for (const userDirection of [userRoute, userRoute.slice().reverse()]) {
          for (let i = 0; i < userDirection.length; i++) {
            for (const point of truckRoute) {
              const distance = geolib.getDistance(userDirection[i], point);
              minDistance = Math.min(minDistance, distance * weightFactors[i]);
            }
          }
        }
        
        // Adjust threshold as needed (50 km here)
        const threshold = 50 * 1000;
        return minDistance <= threshold;
      }

      function isRouteConsistent(userRoute, truckRoute) {
        const userBearing1 = calculateBearing(userRoute[0], userRoute[1]);
        const userBearing2 = calculateBearing(userRoute[1], userRoute[0]);
      
        for (let i = 0; i < truckRoute.length - 1; i++) {
          const truckSegment = truckRoute.slice(i, i + 2);
          if (!isRouteSegmentConsistent(userBearing1, truckSegment) && !isRouteSegmentConsistent(userBearing2, truckSegment)) {
            return false;
          }
        }
        return true;
      }
  
      const userRoute = [userPickUp, userDropOff];
      const viableRoutes = truckRoutes.filter((truckRoute) => {
        const truckRouteDistance = calculateTotalDistance(truckRoute.address);
        const userRouteDistance = calculateTotalDistance(userRoute);
        
        const toleranceLowerBound = userRouteDistance * 0.9;
        const toleranceUpperBound = userRouteDistance * 1.1;
        const distanceCheck = truckRouteDistance >= toleranceLowerBound && truckRouteDistance <= toleranceUpperBound;

        const directionalityCheck = isDirectionAligned(
          userRoute,
          truckRoute.address
        );
        const proximityCheck = calculateProximityScore(
          userRoute,
          truckRoute.address
        );
        const consistencyCheck = isRouteConsistent(userRoute, truckRoute.address);
  
        return (
          //distanceCheck &&
          directionalityCheck &&
          proximityCheck &&
          consistencyCheck
        );
      });

      if (viableRoutes.length > 0) return true
      return false;

    }

    
    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading /> :
            <div>
              {props.showAllLocations && <div style={{ textAlign: "center", width: "100%" }}>
                  <Button
                    color="primaryButton"
                    size="lg"
                    rel="noopener noreferrer"
                    className={classes.items}
                    onClick={clearFilterAndMatch}
                    style={{
                      height: 40,
                      marginLeft: "10px",
                      width: "150px",
                      minWidth: "200",
                      backgroundColor: "#01aac1",
                    }}
                  >
                    <i
                      className="fas fa-times-circle"
                      style={
                        isRTL === "rtl"
                          ? { marginLeft: "10px" }
                          : { marginRight: "10px" }
                      }
                    />
                    {t("clear_button")}
                  </Button>
                  <Button
                    color="primaryButton"
                    size="lg"
                    rel="noopener noreferrer"
                    className={classes.items}
                    style={{
                      height: 40,
                      marginLeft: "10px",
                      width: "150px",
                      minWidth: "200",
                      backgroundColor: "#01aac1",
                    }}
                    onClick={() => props.setBoardType("On Table")}>

                       View on Table
                     </Button>

                </div>}
                {/* {mylocation ? */}
                <div style={{ marginTop: "5px" }}>
                    {/* <Typography variant="h4" style={{margin:"20px 20px 0 15px",textAlign:isRTL==='rtl'?'right':'left'}}>{'Real-Time Map'}</Typography> */}
                    <Map mapcenter={centerOfMap} 
                        locations={props.showAllLocations?locations:[]}
                        loadingElement={<div style={{ height: `65vh` }} />}
                        containerElement={<div />}
                        mapElement={<div style={{ height: `65vh` }} />}
                        pickupAddress={props.pickupAddress}
                        dropAddress={props.dropAddress}
                        setPickupAddress={props.setPickupAddress}
                        setDropAddress={props.setDropAddress}

                        searchedlocationValue={props.searchedlocationValue}
                        setSearchedlocationValue={props.setSearchedlocationValue}
                        searchedRadiusValue={props.searchedRadiusValue}
                        setSearchedRadiusValue={props.setSearchedRadiusValue}

                        checkIfPickUpAndDropIsAlongTheRoutes={checkIfPickUpAndDropIsAlongTheRoutes}
                        
                        selectedDate={props.selectedDate}
                        trailerType={props.trailerType}
                        boardType={props.boardType}
                        history={props.history}
                        ActiveCarrier={"None"}
                        accessdFrom='TruckBoard'
                    />                     
                </div>
    </div>
  );
};

export default AvailabilityProfileMap;
