import React, { useState, useEffect, useContext, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { CircularProgress} from "@material-ui/core";
import { FirebaseContext } from "common";
import { makeStyles } from "@material-ui/core/styles";
import Polyline from '@mapbox/polyline';

import TruckinMap from '../components/TruckinMap';

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { colors } from '../components/Truck/theme';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

const dashboardRoutes = [];

const Trucking =(props)=> {
    const { ...rest } = props;

      const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    
    const params=useParams()
    const { api } = useContext(FirebaseContext);
    const {
        fetchSingleBook,
        getDirectionsApi
    } = api;
    const userdata = useSelector(state => state.usersdata);
    const dispatch = useDispatch();
    const  bookingId = params.BookingId;
    const [modalVisible, setModalVisible] = useState(false);
    const [searchModalVisible, setSearchModalVisible] = useState(false);
    const curBooking = useSelector(state => state.bookinglistdata.singleBook);
    const allBookings = useSelector(state => state.bookinglistdata);
    const cancelReasons = useSelector(state => state.cancelreasondata.complex);
    //const lastLocation = useSelector(state => state.locationdata.coords);
    const [liveRouteCoords, setLiveRouteCoords] = useState(null);
    const mapRef = useRef();
    const pageActive = useRef();
    const [lastCoords, setlastCoords] = useState();
    const [arrivalTime, setArrivalTime] = useState(0);
    const [loading, setLoading] = useState(false);
    const settings = useSelector(state => state.settingsdata.settings);
    const directions = useSelector(state => state.directions);

    const [lastLocation, setLastLocation] = useState(null);
    useEffect(()=>{
      if(bookingId){
        dispatch(fetchSingleBook(bookingId))
      }
   },[fetchSingleBook,dispatch,bookingId])
    useEffect(()=>{
      if(curBooking && (curBooking?.status === "ACCEPTED" ||
          curBooking?.status === 'ARRIVED' ||
          curBooking?.status === 'STARTED' ||
          curBooking?.status === 'REACHED'))
          {
             if(curBooking?.driverDetails?.id){
              dispatch(api.fetchBookingLocations(curBooking?.driverDetails?.id))
                 .then(res=>{
                   setLastLocation(res)
                 })
                 .catch(error=>{
                     setLastLocation(null)
                 })
          }
          else{
              setLastLocation(null)
          }
      }
      else{
          setLastLocation(null)
      }
      if(curBooking?.id){
        dispatch(api.fetchDirections(curBooking?.id))
      }
  
  },[curBooking])


// useEffect(()=>{
//   if(curBooking){
//     if (curBooking?.status == "ACCEPTED" ||curBooking?.status == 'ARRIVED' || curBooking?.status == 'STARTED' ||curBooking?.status == 'REACHED') {
//       dispatch(fetchBookingLocations(bookingId));
//     }
//   }
// },[curBooking])


  useEffect(() => {
      setInterval(() => {
          if (pageActive?.current && curBooking && lastLocation && (curBooking?.status == "ACCEPTED" || curBooking?.status == 'STARTED')) {
              if (lastCoords && lastCoords.lat != lastLocation?.lat && lastCoords.lat != lastLocation?.lng) {
                  if (!curBooking?.post_request && curBooking?.status == "ACCEPTED") {
                      let point1 = { lat: lastLocation?.lat, lng: lastLocation?.lng };
                      let point2 = { lat: curBooking?.pickup?.lat, lng: curBooking?.pickup?.lng };
                      fitMap(point1, point2);
                  } else if (!curBooking?.post_request) {
                      let point1 = { lat: lastLocation?.lat, lng: lastLocation?.lng };
                      let point2 = { lat: curBooking?.drop?.lat, lng: curBooking?.drop?.lng };
                      fitMap(point1, point2);
                  };


                  if (curBooking?.post_request && curBooking?.status == "ACCEPTED") {
                      let point1 = { lat: lastLocation?.lat, lng: lastLocation?.lng };
                      let point2 = { lat: curBooking?.post_request.origin.lat, lng: curBooking?.post_request.origin.lng };
                      fitMap(point1, point2);
                  } else if (curBooking?.post_request) {
                      let point1 = { lat: lastLocation?.lat, lng: lastLocation?.lng };
                      let point2 = { lat: curBooking?.post_request.destination.lat, lng: curBooking?.post_request.destination.lng };
                      fitMap(point1, point2);
                  }
                  setlastCoords(lastLocation);
              }
          }
      }, 20000);
  }, []);

useEffect(() => {
  pageActive.current = true;
  return () => {
      pageActive.current = false;
  };
}, []);

useEffect(()=>{
  if(!curBooking?.pickup||!curBooking?.drop) return
  if(directions?.loading){
    
  }
  else if(directions?.directions&&directions?.directions?.length>0){
    const point1 = { lat: curBooking?.pickup?.lat, lng: curBooking?.pickup?.lng };
    const point2 = { lat: curBooking?.drop?.lat, lng: curBooking?.drop?.lng };
   setLiveRouteCoords(directions?.directions)
   const bounds = new window.google.maps.LatLngBounds();
   bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
   bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
  } 
  else if(curBooking?.status !== "CANCELLED" && curBooking?.status !== "COMPLETE"){
    const point1 = { lat: curBooking?.pickup?.lat, lng: curBooking?.pickup?.lng };
    const point2 = { lat: curBooking?.drop?.lat, lng: curBooking?.drop?.lng };
    fitMap(point1, point2);
  }
},[curBooking,directions])

useEffect(() => {
  
    if (curBooking && (curBooking?.status == 'NEW'||curBooking?.status == 'ACCEPTED')) {
        setTimeout(() => {
              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(new window.google.maps.LatLng(curBooking?.pickup?.lat, curBooking?.pickup?.lng));
              bounds.extend(new window.google.maps.LatLng( curBooking?.pickup?.lat, curBooking?.pickup?.lng));
              if (mapRef.current) {
                mapRef.current.fitBounds(bounds);
                
             }
            
        }, 1000);
    }
    if (lastLocation&&curBooking && curBooking?.status == 'ARRIVED') {
        setTimeout(() => {
              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(new window.google.maps.LatLng(lastLocation?.lat, lastLocation?.lng));
              bounds.extend(new window.google.maps.LatLng( curBooking?.pickup?.lat, curBooking?.pickup?.lng));
              if (mapRef.current) {
                mapRef.current.fitBounds(bounds);
                
             }
            
        }, 1000);
    }
    if (curBooking && curBooking?.status == 'STARTED') {
      setTimeout(() => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(curBooking?.drop?.lat, curBooking?.drop?.lng));            
        bounds.extend(new window.google.maps.LatLng( curBooking?.drop?.lat,  curBooking?.drop?.lng));
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds);
          
        }
          
      }, 1000);
  }

    if (lastLocation&& curBooking && curBooking?.status == 'REACHED') {
        setTimeout(() => {
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(new window.google.maps.LatLng(lastLocation?.lat, lastLocation?.lng));            
          bounds.extend(new window.google.maps.LatLng( curBooking?.drop?.lat,  curBooking?.drop?.lng));
          if (mapRef.current) {
            mapRef.current.fitBounds(bounds);
            
          }
            
        }, 1000);
    }

}, [lastLocation, curBooking, pageActive.current])  

  const fitMap = (point1, point2) => {
      let startLoc = point1.lat + ',' + point1.lng;
      let destLoc = point2.lat + ',' + point2.lng;
      //  console.log("Coords Checked");
      if (settings.showLiveRoute) {
          getDirectionsApi(startLoc, destLoc, null).then((details) => {
              setArrivalTime(details.time_in_secs ? parseFloat(details.time_in_secs / 60).toFixed(0) : 0);
              let points = Polyline.decode(details.polylinePoints);
              let coords = points.map((point, index) => {
                  return {
                      latitude: point[0],
                      longitude: point[1]
                  }
              })

              setLiveRouteCoords(coords);
              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
              bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
              
              if (mapRef.current) {
                //mapRef.current.fitBounds(bounds);
                
              }
          }).catch((error) => {
                  //console.log(error); 
          });
      } else {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng( point1.lat, point1.lng));
            bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
            
            if (mapRef.current) {
              mapRef.current.fitBounds(bounds);
              
            }
      }
  }

const initialRegion = {
  lat: curBooking?.pickup?.lat,
  lng: curBooking?.pickup?.lng,
};

return (
    <div >
       <Header
        color="landing"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
            <div >
                {curBooking?.status === 'CANCELLED' || curBooking?.status === 'COMPLETE' || !curBooking?.approved ?
                    <h3 style={{textAlign:"center", fontWeight:"700",font: '35px Open Sans, sans-serif',color: 'white',height:"70vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        Tracking {curBooking?.approved ? curBooking?.status === 'CANCELLED'?"Cancelled":"Completed" : "Not Approved"}
                    </h3>
                    :
                    <>
                        {curBooking ?
                          <div style={{width:"100%",minHeight:"700px",maxHeight:"88vh",overflow:"hidden"}}>
                                  
                                <TruckinMap  
                                  history = {props.history}
                                  mapRef={mapRef}
                                  colors={colors}
                                  curBooking={curBooking}
                                  role={'none'}
                                  liveRouteCoords={liveRouteCoords}
                                  lastLocation={lastLocation}
                                  center={initialRegion}
                                  loadingElement={<div style={{ height: `80vh` }} />}
                                  containerElement={<div />}
                                  mapElement={<div style={{ height: `87vh` }} />}
                                />
        
                          </div>
                              
                          :
                            <>
                              {!allBookings.singleloading?
                                  <h3 style={{textAlign:"center", fontWeight:"700",font: '35px Open Sans, sans-serif',color: 'white',height:"70vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    404: Tracking Not Found
                                  </h3>
                                  :
                                  <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"60vh"}}><CircularProgress/></div>
                                }
                            </>
                          } 
                    
                    </>
                 }

             </div>
       <Footer />
    </div>
  )
}

export default Trucking       



/**
 * 
 */