import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
  Input
} from "@material-ui/core";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/background.gif";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AlertDialog from '../components/AlertDialog';
import CountrySelect from '../components/CountrySelect';
import { FirebaseContext } from 'common';
import axios from 'axios';
import { Link, useMediaQuery } from '@mui/material';
// import HeroImg from '../assets/img/BG_HERO_DESIGN.png';
import HeroImg from '../assets/img/newBgImage.png';
import styled from "styled-components";
import { Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  ...styles,
  carphoto: {
    height: '16px',
    marginRight: '10px'
  },
}));



export default function LoginPage(props) {
  const { api, authRef, RecaptchaVerifier } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const {
    clearLoginError,
    mobileSignIn,
    countries,
    requestEmailOtp,
    verifyEmailOtp,
    checkUserExists,
    validateReferer,
    mainSignUp
  } = api;

  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();  

  const [data, setData] = React.useState({
    email: '',
    pass: '',
    country: null,
    contact: '',
    companyName:"",
    website:"",
    password: '',
    otp: '',
    verificationId: null,
    firstName: '',
    lastName: '',
    actionType:"broker",
    selectedcountry: null,
    usertype: 'shipper',
    entryType: null
  });

  const [registerData, setRegisterData] = useState({
    usertype: 'shipper',
    firstName: '',
    lastName: '',
    email: '',
    channelId:"",
    channelLink:"",
    companyName:"",
    actionType:"broker",
    website:"",
    selectedcountry: null,
    mobile: '',
    carrierData: {
      allowedToOperate: '',
      dotNumber: '',
      legalName: '',
      dbaName: '',
      phyStreet: '',
      phyCity: '',
      phyState: '',
      phyZip: '',
      phyCountry: ''
    }
  });

  const [otpCalled, setOtpCalled] = useState(false);

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [registerOrLogin, setRegisterOrLogin] = useState("login");
  const [countryCode, setCountryCode] = useState();

  const [searchQuery, setSearchQuery] = useState('');
  const [carrierDataFull, setCarrierDataFull] = useState({});


  const classes = useStyles();
  const { ...rest } = props;

  // ---- hard coded helper objects
  const radio_props = [
    { label: "shipper", value: 0, text: "Freight Broker/Forwarder" },
    { label: "fleetadmin", value: 1, text: "Carrier" },
    { label: "dispatcher", value: 2, text: "Dispatch" },
  ];

  // login validation
  useEffect(() => {
    if (auth.info) {
      if (auth.info.profile) {
          props.history.push('/');
      }
    }
    if (auth.error && auth.error.flag && auth.error.msg.message !== t('not_logged_in')) {
      if (auth.error.msg.message === t('require_approval')) {
        setCommonAlert({ open: true, msg: t('require_approval') })
      } else if(auth.error?.msg?.error){
          setCommonAlert({ open: true, msg: auth.error?.msg?.error })
          if(!auth.verificationId) setData({ ...data, verificationId: null })
      }
    }
    if (auth.verificationId && otpCalled) {
      setOtpCalled(false);
      setData({ ...data, verificationId: auth.verificationId });
    }
   
  }, [auth.info, auth.error, auth.verificationId, props.history, data, RecaptchaVerifier, t,]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' });
    setOtpCalled(false)
    if (auth.error.flag) {
      setData({ ...data, email: '', pass: '' });
      dispatch(clearLoginError());
    }
  };

  // ------ handle login
  let [progressLogIn,setProgressLogIn]=useState(false)
  let [errorLogIn,setErrorLogIn]=useState(false)

  const handleGetOTP = (e) => {
    e.preventDefault();
    //if (data.country) {
      if (data.contact) {
        if (isNaN(data.contact)) {
          setData({ ...data, entryType: 'email' });
          const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (re.test(data.contact)) {
            setProgressLogIn(true);
            //dispatch(requestEmailOtp(data.contact));
            setOtpCalled(true);
            dispatch(requestEmailOtp(data.contact)).then((res) => {
              setProgressLogIn(false);
              //setCommonAlert({ open: true, msg: "Invalid user"})
            }) .catch((error) => {
              setProgressLogIn(false);
              setOtpCalled(false);
              setErrorLogIn(error.msg||"Error occured wile sending code please try again")
              setTimeout(()=>{
                setErrorLogIn("")
              },9000)                      
            });
          } else {
            setCommonAlert({ open: true, msg: t('proper_email') })
          }
        } else {
          setData({ ...data, entryType: 'mobile' });
          let formattedNum = data.contact.replace(/ /g, '');
          if (formattedNum.length > 6) {
            const phoneNumber = "+" + data.country + formattedNum;
            const appVerifier = window.recaptchaVerifier;
            authRef
              .signInWithPhoneNumber(phoneNumber, appVerifier)
              .then(res => {
                setData({ ...data, verificationId: res.verificationId })
              })
              .catch(error => {
                setCommonAlert({ open: true, msg: error.code + ": " + error.message })
              });
          } else {
            setCommonAlert({ open: true, msg: t('mobile_no_blank_error') })
          }
        }
      } else {
        setCommonAlert({ open: true, msg: "Please enter a valid email." })
      }
    // } else {
    //   setCommonAlert({ open: true, msg: t('country_blank_error') })
    // }
  }
  let [loadVerification,setLoadVerification]=useState(false)
  let [errorVerification,setErrorVerification]=useState("")

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (data.otp && data.otp.length === 6) {
      setLoadVerification(true)
      if (data.entryType === 'email') {
        dispatch(verifyEmailOtp(
          data.contact,
          data.otp
        )).then(() => {
          setLoadVerification(false);
        }) .catch((error) => {
          console.error("Error occurred while dispatching:", error);
          setLoadVerification(false);
          setErrorVerification("Invalid code")
          setTimeout(()=>{
            setErrorVerification("")
          },9000)                      
        });


      } else {
        
        dispatch(mobileSignIn(
          data.verificationId,
          data.otp
        )).then(() => {
          setLoadVerification(false);
        }) .catch((error) => {
          //console.error("Error occurred while dispatching:", error);
          setLoadVerification(false);
          setErrorVerification("Invalid code")
          setTimeout(()=>{
            setErrorVerification("")
          },9000)                     
        });


      }
    } else {
      setCommonAlert({ open: true, msg: t('otp_validate_error') })
    }
  }

  const handleCancel = (e) => {
    setData({
      ...data,
      contact: null,
      verificationId: null
    });
  }

  // --- setting and helped functions
  useEffect(() => {
    if (settings) {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].label === settings.country) {
          setRegisterData({
            ...registerData,
            selectedcountry: countries[i],
          });
        }
      }
    }
  }, [settings, countries]);

  const onCountryChange = (object, value) => {
    if (value && value.phone) {
      setRegisterData({ ...registerData, country: value.phone, selectedcountry: value });
    }
  };

  useEffect(() => {
    if (settings) {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].label == settings.country) {
          setCountryCode(settings.country + " (+" + countries[i].phone + ")");
        }
      }
    }
  }, [settings]);

  // --------- sign up handler functions and onChanges

  const [searchLoad,setSearchLoad]=useState(false)
  const [searchError,setSearchError]=useState(false)
  const handleSearch = async (event) => {
    event.preventDefault()
    setSearchError(false)
    setCarrierDataFull({})
    setRegisterData({...registerData, carrierData: {
      allowedToOperate: "",
      dotNumber: "",
      legalName: "",
      dbaName: "",
      phyStreet: "",
      phyCity: "",
      phyState: "",
      phyZip: "",
      phyCountry: "",
    }});
    setSearchLoad(true)
    try {
      const response = await axios.get(
        `https://mobile.fmcsa.dot.gov/qc/services/carriers/${searchQuery}?webKey=25842732e28aa04a34e3c87196eb3c1a7c451ee4`
      );

      const carrierInfo = response.data?.content?.carrier; // Assuming the first result is the one you want
      if(carrierInfo){
          setCarrierDataFull(carrierInfo);
          setRegisterData({...registerData, carrierData: {
            allowedToOperate: carrierInfo.allowedToOperate,
            dotNumber: carrierInfo.dotNumber,
            legalName: carrierInfo.legalName,
            dbaName: carrierInfo.dbaName,
            phyStreet: carrierInfo.phyStreet,
            phyCity: carrierInfo.phyCity,
            phyState: carrierInfo.phyState,
            phyZip: carrierInfo.phyZip,
            phyCountry: carrierInfo.phyCountry,
          }});
      }else{
        setSearchError("DOT search error, please try again")
      
      }
      setSearchLoad(false)

    } catch (error) {
      setSearchLoad(false)
      setSearchError("DOT search error, please try again")
      //console.error('Error fetching data:', error);
    }
  };


  const onInputChange = (event) => {
    setData({ ...data, [event.target.id]: event.target.value })
  }

  const onRegistrationInputChange = (event) => {
    setRegisterData({ ...registerData, [event.target.id]: event.target.value })
  }

  const onRegister = (event) => {
    setRegisterOrLogin(event)
  }

  const onUserTypeRadioChange = (event) => {
    if (event.target.value == 0) {
      setRegisterData({ ...registerData, ["usertype"]: "shipper" })
    } else if (event.target.value == 1) {
      setRegisterData({ ...registerData, ["usertype"]: "fleetadmin" })
    }
    else{
      setRegisterData({ ...registerData, ["usertype"]: "dispatcher" })
    }
  }

  const validateMobile = () => {
    let mobileValid = true;
    if (registerData.mobile.length < 6) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t('mobile_no_blank_error') })
    }
    return mobileValid;
  }

  let [loadSignUp,setLoadSignUp]=useState(false)
  let [showInputFields,setShowInputFields]=useState(false)
  useEffect(()=>{
    if( Object.entries(carrierDataFull).length === 0){
      setShowInputFields(true)
    }
    else{
      setShowInputFields(false)
    }
  },[carrierDataFull])


  const validateRegistrationData = () => {
    setLoadSignUp(true)
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(showInputFields&&registerData.usertype === "fleetadmin"&&registerData?.selectedcountry?.code==='US'){
      setLoadSignUp(false)
      setCommonAlert({ open: true, msg: "Dot Number is Required, Please enter valid DOT Number value" })
    }
    else if(!registerData.firstName || !registerData.lastName ||!registerData.email || !registerData.mobile){
      setLoadSignUp(false)
      setCommonAlert({ open: true, msg: "First Name, Last Name, Email and Mobile are required" })
    }
    else if (re.test(registerData.email)) {
          if (/\S/.test(registerData.firstName) && registerData.firstName.length > 0 && /\S/.test(registerData.lastName) && registerData.lastName.length > 0) {
            if (validateMobile()) {
              const userData = { ...registerData,mobile: "+" + registerData?.selectedcountry?.phone + registerData?.mobile};
              if (userData.usertype == 'shipper' || userData.usertype==='dispatcher') {
                  delete userData.carrierData;
                }
                if(!registerData?.companyName || registerData?.companyName.length<3){
                  setLoadSignUp(false)
                  setCommonAlert({ open: true, msg: "Invalid Company name"})
                  return
                }

                registerUser(userData);
            } else {
              setLoadSignUp(false)
              setCommonAlert({ open: true, msg: t('mobile_no_blank_error') })
            }
          } else {
            setLoadSignUp(false)
            setCommonAlert({ open: true, msg: t('proper_input_name') })
          }

    } else {
      setLoadSignUp(false)
      setCommonAlert({ open: true, msg: t('proper_email') })
    }
  }
  
  const registerUser = async (regData) => {
    
    if(regData.usertype==='dispatcher') regData["Independent"]=true
    checkUserExists(regData)
     .then((res) => {

      if (res.users && res.users.length > 0) {
        setLoadSignUp(false)
        setCommonAlert({ open: true, msg: t('user_exists') })
      }
      else if (res.error) {
        setLoadSignUp(false)
        setCommonAlert({ open: true, msg: res.exist? res.error :t('email_or_mobile_issue') })
      }
      else {
        if (regData.referralId && regData.referralId.length > 0) {
          validateReferer(regData.referralId)
            .then((referralInfo) => {
            if (referralInfo.uid) {
                  mainSignUp({ ...regData, signupViaReferral: referralInfo.uid })
                  .then((res) => {
                      if (res.uid) {
                        setLoadSignUp(false)
                        setCommonAlert({ open: true, msg: t('account_create_successfully') });
                        setTimeout(()=>{
                          window.location.href = "/login";
                        },4000)
                      } else {
                        setLoadSignUp(false)
                        setCommonAlert({ open: true, msg: t('reg_error') });
                      }
                    })
                    .catch((error) => {
                      //console.log("An error occurred:", error);
                    });
                  
            } else {
              setLoadSignUp(false)
              setCommonAlert({ open: true, msg: t('referer_not_found') });
            }
          }).catch((error) => {
            setLoadSignUp(false)
            setCommonAlert({ open: true, msg: t('referer_not_found') });
          });
        } else {
          mainSignUp(regData)
           .then((res) => {
                if (res.uid) {
                  setLoadSignUp(false)
                  setCommonAlert({ open: true, msg: t('account_create_successfully') });
                  setTimeout(()=>{
                    window.location.href = "/login";
                  },4000)
                } else {
                  //console.log(res?.error);
                  setLoadSignUp(false)
                  setCommonAlert({ open: true, msg: res?.error||t('reg_error') });
                }
              })
            .catch((error) => {
                console.log("An error occurred:", error);
              });
        }
      }
    }).catch((error) => {
      setCommonAlert({ open: true, msg: "Server error occured" })
      setLoadSignUp(false)
    });
    
  }


  const handleChange = (event) => {
    setRegisterData({...registerData,actionType:event.target.value});
  };
 

  return (
    <div>
      <Header
        absolute
        color="landing"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
        <Div12 style={{
                    backgroundImage: `url(${HeroImg})`,
                     backgroundPosition: 'center',
                     backgroundSize: 'cover',
                    //  backgroundRepeat: 'repeat'
                }}>
            <div className={classes.container}>
              <GridContainer justify="center" >
                <GridItem xs={10} sm={10} md={10}>
                  <Card>
                    <form className={classes.for}>
                      
                      {registerOrLogin === "register" ?
                        <CardHeader color="info" className={classes.cardHeader}>
                          <h2 style={{ fontWeight: "bold" }}>Registration</h2>
                          <div className={classes.socialLine}>
                            <FormControl component="fieldset">
                              {/* <FormLabel component="legend" style={{ color: 'black' }}>Account Type</FormLabel> */}
                              <RadioGroup row={true} name="usertype" value={radio_props.find(item => item.label === registerData.usertype).value} onChange={onUserTypeRadioChange}>
                                {radio_props.map((element, index) =>
                                  <FormControlLabel
                                    key={element.label}
                                    value={index}
                                    control={<Radio color="default" />}
                                    label={
                                      <span style={{ color: 'white' }}>{element.text}</span>
                                    }
                                  />
                                )}
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {/* <p>{"Broker accounts with multiple reps, and Carrier accounts with multiple trucks and fleetadmins coming soon!"}</p> */}
                        </CardHeader>

                        : null}



                      {registerOrLogin === "register" ? (

                        <CardBody>
                      

                          {(registerData.usertype === "shipper"||registerData.usertype === "dispatcher") &&
                            <>
                            {registerData.usertype === "dispatcher"&&
                             <div style={{marginBottom:"10px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                               Become an independent dispatcher today on Freight Canvas. Work with large carriers and owner operators to dispatch their fleet/truck. Build a network with brokers and freight forwarders
                            </div>}

                            <CountrySelect
                              countries={countries}
                              label={t('select_country')}
                              value={registerData.selectedcountry}
                              onChange={onCountryChange}
                              style={{ paddingTop: 20 }}
                            />

                          {registerData.usertype === "shipper"&&
                                <FormControl style={{marginTop:"10px"}}>
                                    <FormLabel id="demo-controlled-radio-buttons-group" style={{margin:"5px 0 5px"}}>Are You</FormLabel>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name="controlled-radio-buttons-group"
                                      value={registerData.actionType}
                                      onChange={handleChange}
                                      row
                                    >
                                      <FormControlLabel value="forwarder" control={<Radio />} label="Freight Forwarder" />
                                      <FormControlLabel value="broker" control={<Radio />} label="Freight Broker" />
                                    </RadioGroup>
                                 </FormControl>
                                }

                              <CustomInput
                                labelText="First Name (Admin)"
                                id="firstName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: 'text',
                                }}
                                onChange={onRegistrationInputChange}
                                value={registerData.firstName}
                              />
                              <CustomInput
                                labelText="Last Name (Admin)"
                                id="lastName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: 'text',
                                }}
                                onChange={onRegistrationInputChange}
                                value={registerData.lastName}
                              />
                              <CustomInput
                                labelText="Mobile"
                                id="mobile"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: 'tel',
                                  startAdornment: <InputAdornment  position="start">
                                     +{registerData?.selectedcountry?.phone||1}
                                  </InputAdornment>,
                                }}
                                onChange={onRegistrationInputChange}
                                value={registerData.mobile}

                              />
                              <CustomInput
                                labelText="Email"
                                id="email"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: 'email'
                                }}
                                onChange={onRegistrationInputChange}
                                value={registerData.email}
                              />

                              <CustomInput
                                labelText={<span >Company Name</span>}
                                  id="companyName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  onChange={onRegistrationInputChange}
                                  value={registerData.companyName}
                                />

                                <CustomInput
                                labelText={<span >Website</span>}
                                  id="website"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: 'website',
                                    startAdornment:  <InputAdornment  position="start">
                                         <span>https://</span>
                                      </InputAdornment>
                                    ,
                                  }}
                                  onChange={onRegistrationInputChange}
                                  value={registerData.website}
                                />

                                  <CustomInput
                                    labelText="Telegram Channel Link"
                                    id="channelLink"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'channelLink',
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          
                                          <span>https://t.me/</span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.channelLink}
                                  />

                                  <CustomInput
                                    labelText="Telegram Channel Id"
                                    id="channelId"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'channelId',
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <span >-100</span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.channelId}
                                  />

                            </>
                          }
                          {registerData.usertype === "fleetadmin" && <>

                              <div style={{marginBottom:"15px"}}>
                                <CountrySelect
                                  countries={countries}
                                  label={t('select_country')}
                                  value={registerData.selectedcountry}
                                  onChange={onCountryChange}
                                  style={{ paddingTop: 20 }}
                                /> 

                              </div>
                              

                              {registerData.selectedcountry?.code==='US'&&<div >
                                <GridItem xs={12} sm={12} md={12} lg={12} style={{margin:"8px 0 3px 0rem" }} >
                                  <FormLabel component="legend" >Before you begin, please enter your DOT number to populate basic info.</FormLabel>
                                  <div style={{ display:"flex",justifyContent: "flex-start", alignItems: "center",marginTop:"15px",width:"100%"}}>
                                    <Input
                                      type="text"
                                      placeholder="DOT Number"
                                      value={searchQuery}
                                      onChange={(e) => setSearchQuery(e.target.value)}
                                      style={{width:"100%"}}
                                      
                                    />

                                    {searchLoad?
                                      <CircularProgress style={{color:"black"}} size={25}/>:
                                      <Button simple   style={{color:"#1e7d78",fontWeight:600}} color="primary" size="lg" onClick={handleSearch}>
                                        Search
                                      </Button>
                                      }
                                  </div>
                                  {searchError&&<div style={{color:"#ad475a"}}>{searchError}</div>}
                                </GridItem>

                                {Object.entries(carrierDataFull).length !== 0 && (
                                  <TableContainer component={Paper}>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>FMCSA Data</TableCell>
                                          <TableCell>-</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow key={"dotNumber"}>
                                          <TableCell>DOT Number</TableCell>
                                          <TableCell>{carrierDataFull.dotNumber}</TableCell>
                                        </TableRow>
                                        <TableRow key={"legalName"}>
                                          <TableCell>Legal Name</TableCell>
                                          <TableCell>{carrierDataFull.legalName}</TableCell>
                                        </TableRow>
                                        <TableRow key={"dbaName"}>
                                          <TableCell>Doing Business As</TableCell>
                                          <TableCell>{carrierDataFull.dbaName}</TableCell>
                                        </TableRow>
                                        <TableRow key={"phyStreet"}>
                                          <TableCell>Physical Address</TableCell>
                                          <TableCell>{`${carrierDataFull.phyStreet}, ${carrierDataFull.phyCity}, ${carrierDataFull.phyCountry}`}</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </div>}

                              <div >     
                                  <CustomInput
                                    labelText="First Name (Admin)"
                                    id="firstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'text',
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.firstName}
                                  />
                                  <CustomInput
                                    labelText="Last Name (Admin)"
                                    id="lastName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'text',
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.lastName}
                                  />
                                  <CustomInput
                                    labelText="Mobile"
                                    id="mobile"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'tel',
                                      startAdornment: <InputAdornment  position="start">+{registerData?.selectedcountry?.phone||1}</InputAdornment>,
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.mobile}    

                                  />
                                  <CustomInput
                                    labelText="Email"
                                    id="email"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'email'
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.email}
                                  />

                                  <CustomInput
                                      labelText={<span >Company Name</span>}
                                        id="companyName"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        onChange={onRegistrationInputChange}
                                        value={registerData.companyName}
                                      />



                                    <CustomInput
                                      labelText={<span >Website</span>}
                                        id="website"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          type: 'website',
                                          startAdornment: (
                                            <InputAdornment  position="start">
                                              <span >https://</span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={onRegistrationInputChange}
                                        value={registerData.website}
                                      />
                                  <CustomInput
                                    labelText="Telegram Channel Link"
                                    id="channelLink"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'channelLink',
                                      startAdornment: (
                                        <InputAdornment  position="start">
                                         <span >https://t.me/</span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.channelLink}
                                  />

                                  <CustomInput
                                    labelText="Telegram Channel Id"
                                    id="channelId"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: 'channelId',
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <span > -100</span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={onRegistrationInputChange}
                                    value={registerData.channelId}
                                  />

                              </div>
                          </>
                          }

                        </CardBody>
                      ) : (
                        <CardBody>

                          <CustomInput
                            labelText="Enter Email"
                            id="contact"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              required: true,
                              disabled: data.verificationId ? true : false,
                              endAdornment: (
                                <InputAdornment position="start">
                                  <AccountBoxIcon className={classes.inputIconsColor} style={{fontSize: 30}} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={onInputChange}
                            value={data.contact}
                          />
                        

                          {data.verificationId ?    // OTP
                            <CustomInput
                            labelText={<span>One time code</span>}
                              id="otp"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "password",
                                required: true,
                                endAdornment: (
                                  <InputAdornment  position="start">
                                    <Icon className={classes.inputIconsColor}>
                                      lock_outline
                                    </Icon>
                                  </InputAdornment>
                                ),
                                autoComplete: "off"
                              }}
                              onChange={onInputChange}
                              value={data.otp}
                            />
                            : null}
                        </CardBody>

                      )

                      }
                      <CardFooter className={classes.cardFooter} style={{flexDirection:registerOrLogin === "register" ?"column-reverse":"column"}}>

                        {!data.verificationId && registerOrLogin === "register" ?

                          <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"10px",fontSize:"13px"}}>
                              <div style={{fontWeight:400}}>Already have an account ?</div>
                              <a  style={{color:"rgba(115, 2, 2)",fontWeight:"500",cursor:"pointer"}} onClick={() => onRegister("login")}>
                                Login
                              </a>
                          </div>
                          : !data.verificationId ?
                              <>
                                {errorLogIn&&<div style={{color:"red",fontSize:"13px"}}>{errorLogIn}</div>}
                                {otpCalled||progressLogIn?
                                  <div style={{textAlign:"center"}}><CircularProgress style={{color:"black"}} size={20}/></div>
                                  :
                                  <>
                                  <Button style={{background:"#0a315c"}} type="submit" onClick={handleGetOTP}>
                                    Login
                                  </Button>
                                 
                                 </>
                                }
                              
                              </>
                            
                            : null
                        }
                        {data.verificationId ?
                          <>
                              {errorVerification&&<div style={{color:"red",fontSize:"13px"}}>{errorVerification}</div>}
                              {loadVerification?
                                <div style={{textAlign:"center"}}><CircularProgress style={{color:"black"}}  size={20}/></div>
                              :<Button className={classes.normalButton} style={{color:"#1e7d78",fontWeight:"700"}} simple  size="lg" type="submit" onClick={handleVerifyOTP}>
                                {t('verify_otp')}
                              </Button>}
                          
                          </>
                          : null}

                        {data.verificationId ?
                          <Button className={classes.normalButton} style={{color:"#1e7d78",fontWeight:"700"}} simple size="lg" onClick={handleCancel}>
                            {t('cancel')}
                          </Button>
                          : null}

                        {!data.verificationId && registerOrLogin === "register" ?
                          <>
                            {loadSignUp?
                              <div style={{textAlign:"center"}}><CircularProgress style={{color:"black"}}  size={20}/></div>
                              :<Button className={classes.normalButton} simple style={{color:"#1e7d78",fontWeight:"700"}} size="lg" onClick={validateRegistrationData}>
                              Submit
                            </Button>}
                          
                          </>
                          : 
                          //  !data.verificationId ?
                          //   <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"10px"}}>
                          //       <div style={{fontWeight:"400"}}>Dont have an account ?</div>
                          //       <a  style={{color:"rgba(115, 2, 2)",fontWeight:"500",cursor:"pointer",fontSize:"13px"}} onClick={() => onRegister("register")}>
                          //         Sign Up
                          //       </a>

                          //   </div>
                          //   :
                             null
                        }

                           {/* <Link sx={{color:"inherit",textDecoration:"none"}} href="/contact-us">
                              <Button  style={{background:"#0a315c"}}>
                                  Get Demo 
                                </Button>
                            </Link> */}
                          
                      </CardFooter>
                      
                    </form>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
        </Div12>

        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        <Footer />
    </div>
  );
}

const Div12 = styled.div`
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: flex;
  width: 100%;
  height:100%;
  min-height:70vh;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;