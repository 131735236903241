import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { colors } from "../components/Theme/WebTheme";
import { useTranslation } from "react-i18next";
import HomePagedata from "./HomePageData"
import HeroImg from '../assets/img/background.png';
import styled from "styled-components";

const dashboardRoutes = [];


const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
  pickupDropModals: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    alignItems: 'center',
    width: '400px',
    height: '200px',
    borderRadius: '10px',
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    '@media(max-width: 1100px)': {
      margin: '15% 20%'
    },
    '@media(max-width: 800px)': {
      margin: '20% 15%'
    },
    '@media(max-width: 600px)': {
      margin: '20% 10%'
    },
    '@media(max-width: 530px)': {
      margin: '20% 0%',
      width: '350px',
      padding: '10px'
    }
  }
}));

export default function TruckBoardData(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { ...rest } = props;



  return (
   
          <div  style={{minHeight:"100vh",marginBottom:"20px",overflow:"auto",width:"100%"}}>
            <HomePagedata {...rest}/>
          </div>
             
     
  );
}
