import React, { useState, useEffect, useContext, useRef } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {getContinent} from "../components/getContinent"
import Button from "components/CustomButtons/Button.js";

import moment from "moment";
import {
  Paper,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Modal,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import PublicTruckBoard from "./publicTruckBoard";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { FirebaseContext } from "common";
import { colors } from "../components/Theme/WebTheme";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AvailabilityProfileMap from "./AvailabilityProfileMap";
import { Box, InputAdornment } from "@mui/material";

const dashboardRoutes = [];


const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    'media(max-width:450px)':{
      width:"90%"
    }
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
  pickupDropModals: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    alignItems: 'center',
    width: '400px',
    height: '200px',
    borderRadius: '10px',
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    '@media(max-width: 1100px)': {
      margin: '15% 20%'
    },
    '@media(max-width: 800px)': {
      margin: '20% 15%'
    },
    '@media(max-width: 600px)': {
      margin: '20% 10%'
    },
    '@media(max-width: 530px)': {
      margin: '20% 0%',
      width: '350px',
      padding: '10px'
    }
  }
}));

export default function HomePageData(props) {
  const { api } = useContext(FirebaseContext);
  const { getEstimate, clearEstimate, addBooking, clearBooking } = api;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { ...rest } = props;
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [trailerType, setTrailerType] = useState("Select Trailer Type");
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [searchedlocationValue,setSearchedlocationValue] = useState('')
  const [searchedRadiusValue,setSearchedRadiusValue] = useState(null)
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [estimateRequested, setEstimateRequested] = useState({
    loading: false,
    id: null,
  });
  const [selectedTrailerDetails, setSelectedTrailerDetails] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [role, setRole] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const rootRef = useRef(null);
  const [tempRoute, setTempRoute] = useState();
  const [selectedMethod, setSelectedMethod] = useState(0);

  const [data, setData] = useState([]);

  const emptyRoutePostinglistdataActive = useSelector(
    (state) => state.emptyRoutePostinglistdata.active
  );

  const trailertypes = useSelector((state) => state.trailertypes.trailers);
  const [boardType, setBoardType] = useState("On Table");

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    loadDimensions: "",
    loadWeight:"",
    pickUpInstructions: "",
    deliveryInstructions: "",
    shipperOffer: "",
    pickupDate: moment(new Date()).format('YYYY-MM-DD'),
    dropDate: moment(new Date()).format('YYYY-MM-DD'),
    commodity: "",
    loadIdentification: "",
  });

  // handle input change for InstructionData and Load rate
  const handleChange = (e) => {
    if (e.target.name === "selectedMethod") {
      setSelectedMethod(parseInt(e.target.value));
    } else if (e.target.name === "optionIndex") {
      setInstructionData({
        ...instructionData,
        optionIndex: parseInt(e.target.value),
        optionSelected: selectedTrailerDetails.options[e.target.value],
      });
    } else {
      setInstructionData({
        ...instructionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const extractCityStateFromAddress = (address) => {
    const parts = address.split(", ");
    if (parts?.length !== 4) {
      return address;
    }
    const cityState = parts[parts.length - 2].split(" ");
    if (cityState?.length !== 2) {
      return address;
    }
    const city = parts[1];
    const state = cityState[0];

    return `${city}, ${state}`;
  };


  let [driversAllData, handleDriversAllData] = useState([]);

  useEffect(() => {
    let arrDrivers = [];
    for (let i = 0; i < userdata.users.length; i++) {
      let user = userdata.users[i];
      if (user.usertype === "driver") {
        arrDrivers.push(user);
      }
    }
    handleDriversAllData(arrDrivers);
  }, [userdata.users, boardType]);

  // Checks estimatedata status and sets EstimateModalStatus to true
  useEffect(() => {
    if (estimatedata.estimate && estimateRequested?.loading) {
      setEstimateModalStatus(true);
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile);
    }
  }, [estimatedata.estimate, auth.info, estimateRequested]);

  // Sends to booking screen after a booking has been posted
  useEffect(() => {
    if (bookingdata.booking && bookingdata.booking.mainData.status) {
      dispatch(clearBooking());
      setTempRoute(null);
      props.history.push("/bookings");
    }
  }, [bookingdata.booking, props.history, clearBooking, dispatch]);

  const [phoneAlert, setPhoneAlert] = useState({ open: false, msg: "" });

  // Close PhoneAlert
  const handlePhoneAlertClose = (e) => {
    e.preventDefault();
    setPhoneAlert({ open: false, msg: "" });
  };

  // Sends to edit profile if phone details not available
  const handlePhoneAlertGo = (e) => {
    e.preventDefault();
    props.history.push("/profile");
  };

  // Used directly by shippers on the boards to select a route or a driver and create an estimate

  // Used directly by shippers to get estimate after selecting delivery speed on option modal

  // As soon as its activated, it sets EstimateRequested to true, bringing up the rate
  // and instruction input form.

  let [BookFleetAdmin, HandleBookFleetAdmin] = useState(null);
  const handleGetEstimate = (e, tableData) => {
    e.preventDefault();
    setEstimateRequested({ loading: true, id: tableData.id });
    HandleBookFleetAdmin(tableData?.fleetadmin);
    if (auth.info) {
      if (auth.info.profile.usertype === "shipper"||auth.info?.profile?.brokerId) {
        if (
          auth.info.profile.email === " " ||
          auth.info.profile.firstName === " " ||
          auth.info.profile.lastName === " "
        ) {
          setCommonAlert({ open: true, msg: t("profile_incomplete") });
          setEstimateRequested({ loading: false, id: null });
        } else {

          if (pickupAddress && dropAddress && selectedDate) {
            if (new Date().getTime() > new Date(selectedDate).getTime()) {
              setCommonAlert({
                open: true,
                msg: "Bookings only for future dates, not past dates.",
              });
              setEstimateRequested({ loading: false, id: null });
            } else if (!tableData.canBeBooked) {
              // empty route request error
              setCommonAlert({
                open: true,
                msg: t("not_within_driver_pref"),
              });
              setEstimateRequested({ loading: false, id: null });
            } else {
              // Estimate is clicked from routes or driver availability boards and selected pickup and drop
              // is within post criteria

              //or

              // Estimate is clicked from option modal for normal load posting

              //  EstimateRequested modal stays true until form is filled and confirm booking is called

              const directionService =
                new window.google.maps.DirectionsService();
              directionService.route(
                {
                  origin: new window.google.maps.LatLng(
                    pickupAddress.coords.lat,
                    pickupAddress.coords.lng
                  ),
                  destination: new window.google.maps.LatLng(
                    dropAddress.coords.lat,
                    dropAddress.coords.lng
                  ),
                  travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === window.google.maps.DirectionsStatus.OK) {
                    const route = {
                      distance_in_km:
                        result.routes[0].legs[0].distance.value / 1000,
                      time_in_secs: result.routes[0].legs[0].duration.value,
                      polylinePoints: result.routes[0].overview_polyline,
                    };
                    setTempRoute(route);

                    let estimateRequest = {
                      pickup: pickupAddress,
                      drop: dropAddress,
                      routeDetails: route,
                      post_request: tableData ? tableData : null,
                    };

                    dispatch(getEstimate(estimateRequest));
                  } else {
                    setCommonAlert({
                      open: true,
                      msg: t("place_to_coords_error"),
                    });
                    setEstimateRequested({ loading: false, id: null });
                  }
                }
              );
            }
          } else {
            setCommonAlert({
              open: true,
              msg: t("select_pickup_drop_and_date"),
            });
            setEstimateRequested({ loading: false, id: null });
          }
        }
      }
    } else {
      setCommonAlert({ open: true, msg: t("must_login") });
      setEstimateRequested({ loading: false, id: null });
    }
  };

  let [bookProgress, setBookProgress] = useState(false);

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}

useEffect(()=>{
    setInstructionData({...instructionData,pickupDate:selectedDate})
},[selectedDate])


const confirmBooking = (e) => {
    e.preventDefault();

    const regx1 = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
   if(new Date(instructionData.dropDate).getTime() < new Date().getTime() || new Date(instructionData.pickupDate).getTime() < new Date().getTime()){
      setCommonAlert({ open: true, msg: "The Drop Date and Pickup Date cannot be Past Dates." })
    }
   else if(new Date(instructionData.dropDate).getTime() <= new Date(instructionData.pickupDate).getTime()){
      setCommonAlert({ open: true, msg: "The Drop Date cannot be earlier than the Pickup Date." })
    }
    else if (!regx1.test(instructionData.deliveryPersonPhone)) {
      setCommonAlert({ open: true, msg: "Please use valid phone" });
    } 
    // else if(estimatedata.estimate.post_request?.availableWeight && Number(instructionData.loadWeight) > Number(estimatedata.estimate.post_request?.availableWeight)){
    //        setCommonAlert({ open: true, msg: `The weight of ${estimatedata.estimate.post_request?.availableWeight} Kg is the maximum this driver can handle.` });   
    // }
    else if (
      instructionData.deliveryPersonPhone &&
      instructionData.deliveryPersonPhone.length > 6 &&
      instructionData.pickUpInstructions &&
      instructionData.deliveryInstructions &&
      instructionData.shipperOffer &&
      instructionData.loadDimensions &&
      instructionData.loadWeight
    ) {
      setBookProgress(true);
      //setEstimateModalStatus(false);
      let bookingObject = {
        approved: false,
        fleetadmin: BookFleetAdmin,
        pickup: pickupAddress,
        drop: dropAddress,
        trailerDetails: selectedTrailerDetails ? selectedTrailerDetails : null,
        userDetails: auth.info?.profile?.usertype==='shipper'?{id:auth.info.uid,...auth.info?.profile}:giveIdThenIwillGiveYouData(auth.info?.profile?.brokerId),
        estimate: estimatedata.estimate,
        instructionData: {...instructionData,deliveryPersonPhone:"+"+instructionData.deliveryPersonPhone},
        bookLater: true,
        settings: settings,
        booking_type_admin: false,
        post_request: estimatedata.estimate.post_request
          ? estimatedata.estimate.post_request
          : null,
      };

      dispatch(addBooking(bookingObject))
        .then(() => {
          setBookProgress(false);
          setEstimateModalStatus(false);
          dispatch(clearEstimate());
          setEstimateRequested({ loading: false, id: null });
          // window.location.href="/bookings"
        })
        .catch((error) => { });
    } else {
      setCommonAlert({ open: true, msg: t("please_complete_form") });
    }
  };

  // Handle basic trailer selection
  const handleTrailerSelect = (event) => {
    setTrailerType(event.target.value);
    let trailerDetails = null;
    for (let i = 0; i < trailertypes.length; i++) {
      if (trailertypes[i].name === event.target.value) {
        trailerDetails = trailertypes[i];
        let instObj = { ...instructionData };

        if (Array.isArray(trailertypes[i].options)) {
          instObj.optionSelected = trailertypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedTrailerDetails(trailerDetails);
  };

  // setSelectedDate
  const onDateChange = (event) => {
    if (event.target.value) {
      const currentDate = new Date().getTime();
      const targetDate = new Date(event.target.value).getTime();
      if (targetDate > currentDate) setSelectedDate(event.target.value);
      else setSelectedDate(null)
    }
  };

  // EstimateModalClose
  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested({ loading: false, id: null });
  };

  // EstimateErrorClose
  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested({ loading: false, id: null });
  };

  // BookingErrorClose
  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested({ loading: false, id: null });
  };

  // CommonAlert
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  // set data from redux to state based on board type
  useEffect(() => {
    if (emptyRoutePostinglistdataActive?.length > 0) {
      const sortedData = [...emptyRoutePostinglistdataActive];
      sortedData.sort((a, b) => {
        const dateA = new Date(a.routedate);
        const dateB = new Date(b.routedate);
        return dateA - dateB;
      });

      setData(sortedData);
    }
  }, [emptyRoutePostinglistdataActive]);

  let [generalTabelData, setGeneralTabelData] = useState(null);

  function ExtractNecceseryDataFromEmptyRoute(emptyRoutes) {
    let minimizedRoute = [];
    for (const emptyRoute of emptyRoutes) {
      let singleEmptyRoute = {
        postDate: emptyRoute.postDate,
        routedate: emptyRoute.routedate,
        emptyRouteFrom: emptyRoute.emptyRouteFrom,
        emptyRouteTo: emptyRoute.emptyRouteTo,
        routeType: emptyRoute.routeType,
        distance: emptyRoute.distance,
        id: emptyRoute.id,
        routeRatePerMile:emptyRoute.routeRatePerMile
      };
      minimizedRoute.push(singleEmptyRoute);
    }

    return minimizedRoute;
  }

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < 7; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    const randomNumber = Math.floor(Math.random() * 10000);
    const randomString = result + randomNumber.toString();

    return randomString;
  }

  const [ownerData,setOwnerData] =useState(null)

  useEffect(()=>{
    if(auth.info?.profile?.brokerId){
         const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.brokerId)
         setOwnerData(targetData||null)
    } 
    else if(auth.info?.profile?.dispatcherId){
     const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)
     setOwnerData(targetData||null)
     } 
     else if(auth.info?.profile?.carrierId){
       const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)
       setOwnerData(targetData||null)
     } 
     else{
      setOwnerData({...auth.info?.profile,id:auth?.info?.uid})
     }
 
},[auth])


  useEffect(() => {
    // setTimeout(()=>{

      const combinedDataMap = [];
  
      for (const driver of driversAllData) {
        if( ownerData && ownerData?.actionType !== 'forwarder' && getContinent(giveIdThenIwillGiveYouData(driver.fleetadmin)?.selectedcountry?.code)  !==  getContinent(ownerData?.selectedcountry?.code)){
            continue
        }
        if (!driver.approved || !driver.emailVerified) {
          continue
        }
        let driverData = {
          TelegramId: driver?.TelegramId,
          firstName: driver?.firstName,
          lastName: driver?.lastName,
          id: driver?.id,
        };
        let getEmptyRouteOfDriver = data.filter((e) => e.driver === driver.id);
        if (driver.location && !driver.queue) {
          let combinedData = {
            id: generateRandomString(),
            location: driver?.location,
            travelRadius: driver?.travelRadius,
            trailerType: driver?.trailerType,
            name: driver?.firstName,
            queue: driver?.queue,
            driver: driverData,
            driverActiveStatus: driver?.driverActiveStatus,
            route: driver.location,
            routeType: "Available Capacity",
            fleetadmin: driver.fleetadmin,
            ratePerMile:driver.ratePerMile,
            availableWeight:driver?.availableWeight
          };
          combinedDataMap.push(combinedData);
        }
  
        if (driver?.returnHomeDate && driver.baseCity) {
          if (
            moment(new Date(driver.returnHomeDate)).format("L") ===
            moment(new Date()).format("L") &&
            driver?.location
          ) {
            let combinedData = {
              id: generateRandomString(),
              location: driver?.location,
              travelRadius: driver?.travelRadius,
              trailerType: driver?.trailerType,
              name: driver?.firstName,
              queue: driver?.queue,
              driver: driverData,
              driverActiveStatus: driver?.driverActiveStatus,
              route: driver.returnHomeDate,
              routeType: "Future Capacity",
              baseCity: driver.baseCity,
              fleetadmin: driver.fleetadmin,
              ratePerMile:driver.ratePerMile,
              availableWeight:driver?.availableWeight,
            };
  
            combinedDataMap.push(combinedData);
          }
        }
  
        if (driver.frequentRoute) {
          let combinedData = {
            location: driver?.location,
            id: generateRandomString(),
            route: driver.frequentRoute,
            travelRadius: driver?.travelRadius,
            trailerType: driver?.trailerType,
            name: driver?.firstName,
            queue: driver?.queue,
            driver: driverData,
            driverActiveStatus: driver?.driverActiveStatus,
            frequentRouteDates: driver.frequentRouteDates,
            routeType: "Preferred Lane",
            fleetadmin: driver.fleetadmin,
            ratePerMile:driver.frequentRouteratePerMile,
            availableWeight:driver?.availableWeight
          };
          combinedDataMap.push(combinedData);
        }
  
        if (getEmptyRouteOfDriver.length > 0) {
          for (const emptyRoutes of ExtractNecceseryDataFromEmptyRoute(
            getEmptyRouteOfDriver
          )) {
            let combinedData = {
              route: emptyRoutes,
              location: driver?.location,
              id: generateRandomString(),
              travelRadius: driver?.travelRadius,
              trailerType: driver?.trailerType,
              name: driver?.firstName,
              queue: driver?.queue,
              driver: driverData,
              driverActiveStatus: driver?.driverActiveStatus,
              routeType: "Future Capacity",
              fleetadmin: driver.fleetadmin,
              ratePerMile:emptyRoutes.routeRatePerMile,
              availableWeight:driver?.availableWeight
            };
            combinedDataMap.push(combinedData);
          }
        }
      }
  
      setGeneralTabelData(combinedDataMap);

    // },1000)
  }, [driversAllData, data, boardType,ownerData]);
  // Set role
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile);
    }
  }, [auth.info]);

  const [showAllLocations, setShowAllLocations] = useState(false);

  const searchLocations=()=>{
    setShowAllLocations(true);
  }

  const [sampleModal, setSampleModAL] = useState(true)

  const handleSmapleModal = () => {
    // setSampleModAL(false)
  }

  
  return (         
        <div
              style={{
                borderRadius: "4px",
                zIndex: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >

            {generalTabelData?
               <>
                  {generalTabelData?.length > 0 ?
                      <>
                            {/* Capacity Board inputs */}
                            {boardType === "On Table" ? (
                                <div style={{ width: "90%" }}>
                                  <div
                                    className={classes.containerInput}
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "20px",
                                      flexWrap: "wrap",
                                      margin: "10px 0 10px 0 ",
                                      padding: 0,
                                    }}
                                  >
                                    <div style={{ width: "160px" }}>
                                      <Paper>
                                        <GoogleMapsAutoComplete
                                          placeholder={t("pickup_location")}
                                          variant={"filled"}
                                          value={pickupAddress}
                                          onChange={(value) => {
                                            setPickupAddress(value);
                                          }}
                                        />
                                      </Paper>
                                    </div>

                                    <div style={{ width: "160px" }}>
                                      <Paper>
                                        <GoogleMapsAutoComplete
                                          placeholder={t("drop_location")}
                                          variant={"filled"}
                                          value={dropAddress}
                                          onChange={(value) => {
                                            setDropAddress(value);
                                          }}
                                        />
                                      </Paper>
                                    </div>

                                    <div style={{ width: "fit-content" }}>
                                      {trailertypes && trailerType ? (
                                        <FormControl style={{ width: "100%" }}>
                                          <Select
                                            id="car-type-native"
                                            value={trailerType}
                                            onChange={handleTrailerSelect}
                                            style={{
                                              textAlign: isRTL === "rtl" ? "right" : "left",
                                              height: "58px",
                                            }}
                                            className={
                                              trailerType === t("select_car")
                                                ? classes.inputdimmed
                                                : classes.input
                                            }
                                          >
                                            <MenuItem
                                              value={"Select Trailer Type"}
                                              key={"Select Trailer Type"}
                                              style={{
                                                direction: isRTL === "rtl" ? "rtl" : "ltr",
                                              }}
                                            >
                                              {t("car_type_blank_error")}
                                            </MenuItem>
                                            {trailertypes.map((trailer) => (
                                              <MenuItem
                                                key={trailer.name}
                                                value={trailer.name}
                                                style={{
                                                  direction: isRTL === "rtl" ? "rtl" : "ltr",
                                                }}
                                              >
                                                <img
                                                  src={trailer.image}
                                                  className={
                                                    isRTL === "rtl"
                                                      ? classes.carphotoRtl
                                                      : classes.carphoto
                                                  }
                                                  alt="car types"
                                                />
                                                {t(trailer.name)}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      ) : null}
                                    </div>

                                    <div style={{ width: "fit-content" }}>
                                      <TextField
                                        id="datetime-local"
                                        type="datetime-local"
                                        variant="filled"
                                        fullWidth
                                        className={
                                          isRTL === "rtl"
                                            ? [classes.inputRtl, classes.commonInputStyle]
                                            : classes.commonInputStyle
                                        }
                                        InputProps={{
                                          className: classes.input,
                                          style: {
                                            textAlignLast: isRTL === "rtl" ? "end" : "start",
                                            height: "58px",
                                          },
                                        }}
                                        value={selectedDate || ""}
                                        onChange={onDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) :
                                <div style={{ width: "90%" }}>
                                  {showAllLocations ?
                                    <div
                                      className={classes.containerInput}
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "20px",
                                        flexWrap: "wrap",
                                        margin: "10px 0 10px 0 ",
                                        padding: 0,
                                      }}
                                    >
                                      <div style={{ width: "160px" }}>
                                          <Paper>
                                              <GoogleMapsAutoComplete
                                                placeholder={'Search Location'}
                                                variant={"filled"}
                                                value={searchedlocationValue}
                                                onChange={(value) => {
                                                  setSearchedlocationValue(value);
                                                }}
                                                style={{ width: '160px' }}
                                              />
                                          </Paper>
                                      </div>

                                      <div style={{ width: "160px" }}>
                                          <Paper>
                                              <TextField
                                                placeholder={'Search Radius'}
                                                variant={"filled"}
                                                type="number"
                                                value={searchedRadiusValue}
                                                onChange={(e) => {
                                                  setSearchedRadiusValue(e.target.value);
                                                }}
                                                style={{ width: '160px' }}
                                              />
                                            </Paper>
                                      </div>

                                      <div style={{ width: "fit-content" }}>
                                          <TextField
                                            id="datetime-local"
                                            type="datetime-local"
                                            variant="filled"
                                            fullWidth
                                            className={
                                              isRTL === "rtl"
                                                ? [classes.inputRtl, classes.commonInputStyle]
                                                : classes.commonInputStyle
                                            }
                                            InputProps={{
                                              className: classes.input,
                                              style: {
                                                textAlignLast: isRTL === "rtl" ? "end" : "start",
                                                height: "58px",
                                              },
                                            }}
                                            value={selectedDate || ""}
                                            onChange={onDateChange}
                                          />
                                      </div>


                                    
                                    </div> :
                                    <Modal
                                      disablePortal
                                      disableEnforceFocus
                                      disableAutoFocus
                                      open={sampleModal}
                                      onClose={handleSmapleModal}
                                      className={classes.modal}
                                      container={() => rootRef.current}
                                      style={{
                                        overflow: "auto",
                                        height: "96vh",
                                        paddingTop: "80px",
                                        top: 10,
                                      }}
                                    >
                                      <Grid
                                        container
                                        spacing={1}
                                        className={classes.pickupDropModals}
                                        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr",color:"black",height:"fit-content" }}
                                      >
                                        <Typography >
                                           To view publicly posted capacity on a map, please input a center location and a radius to access relevant capacity.
                                          </Typography>
                                        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px",flexDirection:"column"}}>
                                            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px",flexDirection:"column"}}>
                                                <Box  sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px",flexWrap:"wrap"}}>
                                                    <Paper>
                                                      <GoogleMapsAutoComplete
                                                        placeholder={'Search Location'}
                                                        variant={"filled"}
                                                        value={searchedlocationValue}
                                                        onChange={(value) => {
                                                          setSearchedlocationValue(value);
                                                        }}
                                                        style={{ width: '160px' }}
                                                      />
                                                    </Paper>
                                                    <Paper>
                                                      <TextField
                                                        placeholder={'Radius in mile'}
                                                        variant={"filled"}
                                                        type="number"
                                                        value={searchedRadiusValue}
                                                        onChange={(e) => {
                                                          setSearchedRadiusValue(e.target.value);
                                                        }}
                                                        style={{ width: '160px' }}
                                                      />
                                                    </Paper>

                                                </Box>

                                                <button style={{ backgroundColor: "#01aac1", borderRadius: '5px', border: '0', padding: '15px', cursor: "pointer", color: 'white' }} onClick={searchLocations}>Search</button>
                                            </Box>

                                            <a style={{ color: "#01aac1", borderRadius: '5px', border: '0', padding: '15px', cursor: "pointer", }} onClick={() => setBoardType("On Table")}>View on Table</a>
                                          
                                        </Box>
                                      </Grid>
                                    </Modal>
                                  }
                                </div>
                            }

                            {/* Capacity Board itself */}
                            {generalTabelData.length > 0 && (
                              <div style={{ width: "100%" }}>
                                {boardType === "On Table" ? (
                                  <PublicTruckBoard
                                    setBoardType={setBoardType}
                                    setPickupAddress={setPickupAddress}
                                    setDropAddress={setDropAddress}
                                    setTrailerType={setTrailerType}
                                    pickupAddress={pickupAddress}
                                    dropAddress={dropAddress}
                                    data={generalTabelData}
                                    boardType={boardType}
                                    role={role}
                                    estimateRequested={estimateRequested}
                                    setSelectedDate={setSelectedDate}
                                    handleGetEstimate={handleGetEstimate}
                                    history={props.history}
                                    setCommonAlert={setCommonAlert}
                                    selectedDate={selectedDate}
                                    trailerType={trailerType}
                                  />
                                ) : boardType === "On Map" ? (
                                  <AvailabilityProfileMap
                                    setBoardType={setBoardType}
                                    setTrailerType={setTrailerType}
                                    data={generalTabelData}

                                    searchedlocationValue={searchedlocationValue}
                                    setSearchedlocationValue={setSearchedlocationValue}
                                    searchedRadiusValue={searchedRadiusValue}
                                    setSearchedRadiusValue={setSearchedRadiusValue}

                                    setPickupAddress={setPickupAddress}
                                    setDropAddress={setDropAddress}
                                    pickupAddress={pickupAddress}
                                    dropAddress={dropAddress}
                                    
                                    boardType={boardType}
                                    role={role}
                                    setSelectedDate={setSelectedDate}
                                    handleGetEstimate={handleGetEstimate}
                                    history={props.history}
                                    setCommonAlert={setCommonAlert}
                                    selectedDate={selectedDate}
                                    trailerType={trailerType}
                                    setShowAllLocations={setShowAllLocations}
                                    showAllLocations={showAllLocations}
                                  />) : null}

                              </div>
                            )}
                      
                      </>
                      :
                      <Box sx={{height:"80vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                          <Typography variant="h3" style={{color:"black"}}>No Data is Available</Typography>
                      </Box>
                  
                  }
               </>
               :null
            }

          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={estimateModalStatus}
            onClose={handleEstimateModalClose}
            className={classes.modal}
            container={() => rootRef.current}
            style={{              
            }}
          >
            <Grid
              container
              spacing={1}
              className={classes.paper}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" , overflow: "auto",
              height: "70vh",
              top: 10,}}
            >
              <Typography
                component="h2"
                variant="h5"
                style={{ marginTop: 15, color: "#000" }}
              >
                {t("load_info_and_offer")}
              </Typography>
              {estimatedata.estimate &&
                estimatedata.estimate.post_request &&
                boardType === "Route Board" ? (
                <Grid item xs={12}>
                  <p style={{ marginTop: 10, color: "#000" }}>
                    {t("posted_route_origin")}:{" "}
                    {estimatedata.estimate &&
                      estimatedata.estimate.post_request &&
                      estimatedata.estimate.post_request.fromAddress
                      ? estimatedata.estimate.post_request.fromAddress
                      : ""}{" "}
                    <br></br>
                    {t("posted_route_destination")}:{" "}
                    {estimatedata.estimate &&
                      estimatedata.estimate.post_request &&
                      estimatedata.estimate.post_request.toAddress
                      ? estimatedata.estimate.post_request.toAddress
                      : ""}{" "}
                    <br></br>
                    {t("posted_route_distance")}:{" "}
                    {estimatedata.estimate &&
                      estimatedata.estimate.post_request.distance
                      ? estimatedata.estimate.post_request.distance
                      : ""}{" "}
                    <br></br>
                  </p>
                </Grid>
              ) : null}
              {estimatedata.estimate && estimatedata.estimate.post_request ? (
                <Grid item xs={12}>
                  <p style={{ marginTop: 10, color: "#000" }}>
                    {t("selected_pickup")}:{" "}
                    {pickupAddress && pickupAddress.description
                      ? pickupAddress.description
                      : ""}{" "}
                    <br></br>
                    {t("selected_drop")}:{" "}
                    {dropAddress && dropAddress.description
                      ? dropAddress.description
                      : ""}{" "}
                    <br></br>
                    {t("selected_route_distance")}:{" "}
                    {estimatedata.estimate && estimatedata.estimate.estimateDistance
                      ? estimatedata.estimate.estimateDistance
                      : ""}
                    <br></br>
                  </p>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type={"date"}
                  margin="normal"
                  fullWidth
                  id="dropDate"
                  label={"Drop Date"}
                  name="dropDate"
                  autoComplete="dropDate"
                  onChange={handleChange}
                  value={instructionData.dropDate}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>


              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="loadWeight"
                  label={"Load Weight"}
                  name="loadWeight"
                  autoComplete="loadWeight"
                  type="number"
                  onChange={handleChange}
                  value={instructionData.loadWeight}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                         <span>Kg</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="loadDimensions"
                  label={"Load Dimensions"}
                  name="loadDimensions"
                  autoComplete="loadDimensions"
                  onChange={handleChange}
                  value={instructionData.loadDimensions}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="deliveryPerson"
                  label={t("deliveryPerson")}
                  name="deliveryPerson"
                  autoComplete="deliveryPerson"
                  onChange={handleChange}
                  value={instructionData.deliveryPerson}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  focused
                  id="deliveryPersonPhone"
                  label={t("deliveryPersonPhone")}
                  name="deliveryPersonPhone"
                  placeholder="123456778"
                  type="number"
                  autoComplete="deliveryPersonPhone"
                  onChange={handleChange}
                  value={instructionData.deliveryPersonPhone}
                  className={
                    isRTL === "rtl" ? [classes.inputRtl, classes.rightRty] : null
                  }
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                  }}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="pickUpInstructions"
                  label={t("pickUpInstructions")}
                  name="pickUpInstructions"
                  autoComplete="pickUpInstructions"
                  onChange={handleChange}
                  value={instructionData.pickUpInstructions}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="deliveryInstructions"
                  label={"Delivery Instructions"}
                  name="deliveryInstructions"
                  autoComplete="deliveryInstructions"
                  onChange={handleChange}
                  value={instructionData.deliveryInstructions}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="commodity"
                  label={"Commodity"}
                  name="commodity"
                  autoComplete="commodity"
                  onChange={handleChange}
                  value={instructionData.commodity}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="loadIdentification"
                  label={"Load Identification"}
                  name="loadIdentification"
                  autoComplete="loadIdentification"
                  onChange={handleChange}
                  value={instructionData.loadIdentification}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type={"number"}
                  margin="normal"
                  fullWidth
                  id="shipperOffer"
                  label={"Load Rate"}
                  name="shipperOffer"
                  autoComplete="shipperOffer"
                  onChange={handleChange}
                  value={instructionData.shipperOffer}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                         <span>$ / {estimatedata?.estimate?.post_request?.ratePerMile}$</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                {!bookProgress ? (
                  <>
                    <Button
                      onClick={handleEstimateModalClose}
                      variant="contained"
                      color="primary"
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={confirmBooking}
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 10 }}
                    >
                      {t("book_route")}
                    </Button>
                  </>
                ) : (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <CircularProgress size={25} />
                  </div>
                )}
              </Grid>
            </Grid>
          </Modal>

          <AlertDialog
            open={bookingdata.error.flag}
            onClose={handleBookingErrorClose}
          >
            {bookingdata.error.msg}
          </AlertDialog>
          <AlertDialog
            open={estimatedata.error.flag}
            onClose={handleEstimateErrorClose}
          >
            {estimatedata.error.msg}
          </AlertDialog>
          <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
            {commonAlert.msg}
          </AlertDialog>

          <Dialog
            open={phoneAlert.open}
            onClose={handlePhoneAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("phone_no_update")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePhoneAlertClose} color="primary">
                {t("cancel")}
              </Button>
              <Button onClick={handlePhoneAlertGo} color="primary">
                {t("yes")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        )
}
